import React, { useState, useEffect } from "react";
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';

import './assets/scss/icons.scss';

import {
  Route,
  Routes
} from "react-router-dom";


import Index from "./pages/index.js";
import Loader from "./component/Loader";
import { useTranslation } from "react-i18next";

export default function App() {
  const [loading, setLoading] = useState(false);
  const {i18n} = useTranslation();
  useEffect(() => {
    const savedDir = localStorage.getItem('dir') || 'ltr';
    const savedLanguage = localStorage.getItem('language') || 'en'; 
    document.documentElement.setAttribute('dir', savedDir);
    i18n.changeLanguage(savedLanguage);
    
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    setLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />
      </Routes>
    </>
  );

}
